import * as React from 'react';
import PropTypes from 'prop-types';

class FormWizardPage extends React.Component {

  static propTypes: any

  constructor(props) {
    super(props)
  }

  render() {
    return <>{ this.props.children }</>
  }
}

FormWizardPage.propTypes = {
  children: PropTypes.node,
}

export default FormWizardPage